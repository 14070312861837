<template>
  <div>
    <div class="table_content">
      <commonTitle></commonTitle>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-loading="loading"
        element-loading-text="修改密码中,请稍后..."
      >
        <el-form-item label="旧密码" prop="password">
          <el-input v-model="ruleForm.password" placeholder="请输入旧密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="ruleForm.newPassword" placeholder="请输入新密码" type="password"></el-input>
        </el-form-item>
        <el-form-item label="重复新密码" prop="newPassword1">
          <el-input v-model="ruleForm.newPassword1" placeholder="请再次输入新密码" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="submitForm('ruleForm')">修改密码</el-button>
          <!--                    <el-button @click="back" size="small">返回</el-button>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import commonTitle from "../table/commonTitle";

export default {
  name: "chpwd",
  components: {
    commonTitle
  },
  data() {
    var chePwd = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请重复输入密码!"));
      } else if (value !== this.ruleForm.newPassword) {
        return callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      url: this.$Config.base_server,
      ruleForm: {
        id: "",
        password: "",
        newPassword: "",
        newPassword1: "",
        access_token: localStorage.getItem("token")
      }, //校验规则
      rules: {
        password: [
          { required: true, message: "请输入原密码", trigger: "change" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "change" }
        ],
        newPassword1: [{ required: true, trigger: "change", validator: chePwd }]
      },
      loading: false
    };
  },
  mounted() {
    this.common();
  },
  methods: {
    common() {
      //初始化数据
      this.ruleForm.id = JSON.parse(localStorage.getItem("user")).id;
    },
    submitForm(formName) {
      this.$confirm("确定要修改密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          const _this = this;
          //修改密码按钮
          this.$refs[formName].validate(function(valid) {
            if (valid) {
              _this.loading = true;
              const token = localStorage.getItem("token");
              $.ajax({
                url:
                  _this.url +
                  "/api-public/common/updatePass?access_token=" +
                  token,
                data: JSON.stringify(_this.ruleForm),
                type: "post",
                contentType: "application/json",
                success: function(result) {
                  _this.loading = false;
                  //判断状态 0 修改成功 1修改失败
                  if (result.resp_code == 0) {
                    localStorage.clear();
                    _this.$addStorageEvent(2, "token", "");
                    _this.$addStorageEvent(2, "user", "");
                    _this.$message({
                      message: result.resp_msg,
                      dangerouslyUseHTMLString: true,
                      type: "success"
                    });
                    setTimeout(() => {
                      _this.$router.push("/");
                    }, 1000);
                    $.ajax({
                      url:
                        _this.url +
                        "/api-auth/oauth/remove/token?access_token=" +
                        localStorage.getItem("token"),
                      type: "POST",
                      success: function(res) {
                        //清除本地的数据
                        //删除token
                      }
                    });
                  } else {
                    _this.loading = false;
                    _this.$message({
                      message: result.resp_msg,
                      dangerouslyUseHTMLString: true,
                      type: "error",
                      duration: 1000
                    });
                  }
                }
              });
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      window.history.back(-1);
    }
  }
};
</script>

<style scoped>
.el-form {
  width: 600px;
  margin: 0 auto;
}
</style>